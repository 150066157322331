<template>
  <Modal v-if="showPrivacyModal" @close="$emit('closePrivacy')" :modalClass="'privacy-modal'">
    <template v-slot:body>
      <div class="privacy-body flex flex-col">
        <h3 class="privacy-body__title">Privacy Policy</h3>

        <h4 class="title">1. Information We Collect</h4>
        <p class="description">
          We may collect and store personal information when you use our
          Services, including:
        </p>
        <p class="description">
          Name and Contact Data: We collect your first and last name, email,
          phone number, and other similar contact data.
        </p>
        <p class="description">
          Credentials: We collect passwords and similar security information
          used for authentication and account access.
        </p>
        <p class="description">
          Usage Data: We collect information about how you interact with our
          Services, including the time, frequency, and duration of your
          activities.
        </p>

        <h4 class="title">2. How We Use Your Information</h4>
        <p class="description">We use the information we collect:</p>
        <p class="description">To provide and maintain our Services.</p>
        <p class="description">
          To improve, personalize, and expand our Services.
        </p>
        <p class="description">
          To understand and analyze how you use our Services.
        </p>
        <p class="description">
          To develop new products, services, features, and functionality.
        </p>

        <h4 class="title">3. Sharing and Disclosure</h4>
        <p class="description">
          We do not share or disclose your personal information to third parties
          without your consent, except as specified in this policy.
        </p>

        <h4 class="title">4. Security</h4>
        <p class="description">
          We are committed to protecting your personal information and have
          implemented appropriate technical and organizational security measures
          to protect it against any unauthorized or unlawful processing and
          against any accidental loss, destruction, or damage.
        </p>

        <h4 class="title">5. Changes to this Privacy Policy</h4>
        <p class="description">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <h4 class="title">6. Contact Us</h4>
        <p class="description">
          If you have any questions about this Privacy Policy, please contact us
          at
          <a href="mailto:support@dialogsafe.com" class="link"
            >support@dialogsafe.com</a
          >.
        </p>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "./Modal";

export default {
  name: "privacy-modal",
  components: {
    Modal,
  },
  props: ["showPrivacyModal"],
};
</script>
<style lang="scss" scoped>
.privacy-body {
  font-family: "Forma DJR Cyrilic Variable";
  color: #1D1E21;

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-family: "Forma DJR Cyrilic Bold";
  }

  .title {
    font-size: 20px;
    line-height: 28px;
  }

  .description {
    font-size: 16px;
    line-height: 23px;
    opacity: 0.5;

    .link {
      color: #0047FF;
      cursor: pointer;
    }
  }
}
</style>
