<template>
  <Modal
    v-if="showSuccessModal"
    @close="$emit('close')"
    :modalClass="'success-modal'"
  >
    <template v-slot:body>
      <div class="modal-content flex flex-col items-center">
        <div class="modal-content__header">
          <picture>
            <source
              media="(min-width:480px)"
              srcset="../assets/modal/success.svg"
              width="328"
              height="348"
            />
            <img
              src="../assets/modal/success-sm.svg"
              alt="success image"
              width="237"
              height="249"
            />
          </picture>
        </div>

        <div class="modal-content__info flex flex-col text-center self-center">
          <h4 class="title">Your application has been successfully accepted</h4>

          <p class="description opacity-50">
            Our team is currently reviewing your information, and one of our
            managers will be in touch with you within the next 24 hours
          </p>
        </div>

        <button class="modal-content__btn w-full font-medium" @click="$emit('close')">Ok</button>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "./Modal";
export default {
  name: "success-modal",
  components: {
    Modal,
  },
  props: ["showSuccessModal"],
};
</script>
<style lang="scss">
.modal-content {
  font-family: 'Forma DJR Cyrilic Variable';

  &__info {
    gap: 10px;
    color: #1d1e21;
    max-width: 72.438vw;

    .title {
      font-size: 20px;
      line-height: 24px;
      font-family: 'Forma DJR Cyrilic Bold';
      margin: 0 auto;
    }

    .description {
      font-size: 16px;
      line-height: 23px;
      margin: 0 auto;
    }
  }

  &__btn {
    background: #1BB7A4;
    border-radius: 89px;
    padding: 16px 32px;
    color: #F1FAEE;
    font-size: 18px;
    line-height: 22px;
  }
}

@media (min-width: 480px) {
  .modal-content {
    &__info {
      max-width: 77.7vw;

      .title {
        font-size: 32px;
        line-height: 38px;
        max-width: 373px;
      }

      .description {
        max-width: 67.7vw;
      }
    }
  }
}

@media (min-width: 768px) {
  .modal-content {
    &__info {
      .description {
        max-width: 70.44vw;
      }
    }
  }
}

@media (min-width: 768px) {
  .modal-content {
    &__info {
      .description {
        font-size: 20px;
        line-height: 28px;
        max-width: 40.9vw;
      }
    }
  }
}
</style>
