<template>
  <footer class="footer flex">
    <a href="mailto:support@dialogsafe.com" class="email-link">
      support@dialogsafe.com
    </a>

    <div class="footer-logo flex flex-col items-center">
      <div class="footer-logo__brands flex items-center" @click="scrollTop()">
        <img src="../assets/brand-logo.svg" class="logo" loading="lazy" alt="brand logo icon"/>
        <img src="../assets/brand.svg" class="brand" loading="lazy" alt="brand icon"/>
      </div>
    </div>

    <div class="footer-logo__rights text-white font-light">
      <a href="mailto:support@dialogsafe.com" class="email-link">
        support@dialogsafe.com
      </a>
      <span class="opacity-50">2023 © All Right Reserved</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
};
</script>

<style lang="scss">
.footer {
  background: #264653;
  padding: 121px 19px 31px;
  gap: 7.5px;
  flex-direction: column;
  align-items: center;

  &-logo {
    gap: 2px;

    &__brands {
      gap: 4px;

      .logo {
        width: 28px;
        height: 24px;
      }

      .brand {
        width: 82.14px;
        height: 15.77px;
      }
    }

    &__rights {
      font-family: "Forma DJR Cyrilic Variable";
      font-size: 6.73418px;
      line-height: 8px;
      order: 2;

      .email-link {
        display: none;
      }
    }
  }

  .email-link {
    order: 1;
    font-size: 14px;
    line-height: 23px;
    color: #F1FAEE;
    font-family: "Forma DJR Cyrilic Bold";
  }
}

@media (min-width: 480px) {
  .footer {
    flex-direction: row;
    align-items: center;
    gap: 22.36px;
    padding: 139px 20px 15px;

    .email-link {
      display: none;
    }

    &-logo {
      &__brands {
        .logo {
          width: 50.97px;
          height: 43.96px;
        }

        .brand {
          width: 151.3px;
          height: 29.05px;
        }
      }

      &__rights {
        font-size: 12.4051px;
        line-height: 15px;

        .email-link {
          display: flex;
          font-size: 16px;
          line-height: 23px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .footer {
    justify-content: space-between;
    padding: 70px 35px 16px 20px;

    .email-link {
      display: flex;
      font-family: "Forma DJR Cyrilic Bold";
      font-size: 16px;
      line-height: 23px;
      color: #f1faee;
      letter-spacing: 0.3px;
      order: 0;
    }

    &-logo {
      gap: 14px;
      cursor: pointer;

      &__brands {
        .brand {
          width: 170.76px;
          height: 32.78px;
        }

        .logo {
          width: 57.52px;
          height: 50px;
        }
      }

      &__rights {
        font-size: 14px;
        line-height: 17px;

        .email-link {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .email-link {
    font-size: 18px;
    line-height: 22px;
  }

  .footer {
    padding: 124px 20px 18px;
  }
}

@media (min-width: 1290px) {
  .email-link {
    font-size: 32px;
    line-height: 38px;
  }

  .footer {
    padding: 174px 0 31px;
    justify-content: center;
    gap: 300px;
  }
}
</style>
