<template>
  <div class="sales flex flex-col">
    <div class="sales-info flex flex-col">
      <h2 class="title">Sales</h2>
      <p class="description opacity-60">
        Discover our sales-driven approach, designed to help businesses of all
        sizes and budgets find the perfect LLM solution:
      </p>
    </div>
    <ul class="sales-list flex">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="sales-list__item flex flex-col border-b pb-5 last:pb-0"
      >
        <div class="index">{{ formattedIndex(index) }}</div>
        <h3 class="title">{{ item.title }}</h3>
        <p class="description">{{ item.description }}</p>
      </li>
    </ul>

    <button class="sales-btn w-full" @click="showModal = true">Schedule a Free Consultation</button>
  </div>

  <ConsultantModal
    v-if="showModal"
    showModal="showModal"
    @close="showModal = false"
    @privacyHandle="openPrivacyModal()"
    @handleSuccessModal="openSuccessModal()"
  ></ConsultantModal>

  <PrivacyModal
    v-if="showPrivacyModal"
    showPrivacyModal="showPrivacyModal"
    @closePrivacy="showPrivacyModal = false"
  ></PrivacyModal>

  <SuccessModal
    v-if="showSuccessModal"
    showSuccessModal="showSuccessModal"
    @close="showSuccessModal = false"
  ></SuccessModal>
</template>

<script>
import ConsultantModal from "./ConsultantModal";
import PrivacyModal from "./PrivacyModal";
import SuccessModal from "./SuccessModal";

export default {
  name: "app-sales",
  data: function () {
    return {
      items: [
        {
          title: "Free Consultation",
          description:
            "Schedule a complimentary consultation with our sales team to discuss your business needs, objectives, and requirements.",
        },
        {
          title: "Tailored Pricing Plans",
          description:
            "We'll create a custom pricing plan based on your unique needs, ensuring you get the best value for your investment.",
        },
        {
          title: "Personalized Onboarding",
          description:
            "Our dedicated account managers will guide you through the onboarding process, making it seamless and straightforward.",
        },
        {
          title: "Ongoing Support",
          description:
            "Benefit from continuous support and assistance from our expert team, helping you maximize the potential of our LLM solution.",
        },
        {
          title: "Custom Integrations",
          description:
            "We'll work with you to integrate our LLM solution into your existing systems and tools, ensuring a smooth transition.",
        },
        {
          title: "Upgrade Flexibility",
          description:
            "As your business grows, we'll help you scale your LLM solution to meet your evolving needs, offering flexible upgrades and add-ons.",
        },
      ],
      formattedIndex(value) {
        return "0" + ++value;
      },
      showModal: false,
      showPrivacyModal: false,
      showSuccessModal: false,
    };
  },
  components: {
    ConsultantModal,
    PrivacyModal,
    SuccessModal,
  },
  methods: {
    openPrivacyModal() {
      this.showModal = false;
      this.showPrivacyModal = true;
    },
    openSuccessModal() {
      this.showModal = false;
      this.showPrivacyModal = false;
      this.showSuccessModal = true;
    }
  }
};
</script>

<style lang="scss">
.sales {
  gap: 26px;
  font-family: "Forma DJR Cyrilic Variable";
  color: #1d1e21;

  &-info {
    gap: 10px;

    .title {
      font-family: "Forma DJR Cyrilic Bold";
      font-weight: 700;
      font-size: 25px;
      line-height: 118%;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
    }
  }

  &-list {
    flex-direction: column;
    gap: 20px;

    &__item {
      gap: 10px;
      border-bottom-color: rgba(29, 30, 33, 0.1);

      .index {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: #1bb7a4;
        font-family: "Forma DJR Cyrilic Bold";
      }

      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.6px;
        font-family: "Forma DJR Cyrilic Bold";
      }

      .description {
        font-size: 16px;
        line-height: 23px;
        color: #264653;
        letter-spacing: -0.1px;
      }

      &:nth-child(6) {
        border-bottom-width: 0;
      }
    }
  }

  &-btn {
    padding: 16px;
    background: #1bb7a4;
    border-radius: 89px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #f1faee;

    &:hover {
      background: #E9C46A;
    }
  }
}

@media (min-width: 480px) {
  .sales {
    gap: 30px;

    &-info {
      gap: 13px;

      .title {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 1px;
      }

      .description {
        letter-spacing: -0.1px;
      }
    }

    &-btn {
      margin: 0;
    }

    &-list__item {
      .title {
        letter-spacing: 0.5px;
      }
    }
  }
}

@media (min-width: 768px) {
  .sales {
    &-list {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 0;

      &__item {
        max-width: calc((100vw - 41px) / 2);
        padding-top: 20px;

        &:nth-child(even) {
          padding-left: 10px;
        }

        &:nth-child(odd) {
          border-right-width: 1px;
          padding-right: 10px;
        }

        &:first-child {
          padding-top: 0px;
        }

        &:nth-child(2) {
          padding-top: 0px;
        }

        &:nth-child(5) {
          border-bottom-width: 0;
          padding-bottom: 0 !important;
        }

        &:nth-child(6) {
          padding-bottom: 0 !important;
        }
      }
    }

    &-btn {
      max-width: 289px;
      margin: 0 auto;
    }

    &-info {
      .description {
        max-width: 85%;
      }
    }
  }
}

@media (min-width: 1000px) {
  .sales {
    margin-top: 55px;

    &-info {
      gap: 10px;

      .title {
        font-size: 45px;
        line-height: 118%;
        letter-spacing: 0;
      }

      .description {
        font-size: 18px;
        line-height: 28px;
        max-width: fit-content;
      }
    }

    &-list {
      &__item {
        &:nth-child(even) {
          padding-left: 21px;
        }

        &:nth-child(odd) {
          padding-right: 20px;
        }

        .index {
          font-size: 50px;
          line-height: 60px;
        }

        .title {
          font-size: 32px;
          line-height: 38px;
          letter-spacing: 0.7px;
        }

        .description {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}

@media (min-width: 1290px) {
  .sales {
    gap: 45px;
    margin-top: 150px;

    &-info {
      .title {
        font-size: 60px;
      }

      .description {
        font-size: 20px;
      }
    }

    &-list {
      &__item {
        padding-bottom: 31px !important;
        padding-top: 31px;
        max-width: 50%;

        .description {
          font-size: 20px;
        }

        &:nth-child(odd) {
          padding-right: 65px;
        }

        &:nth-child(even) {
          padding-left: 50px;
        }
      }
    }

    &-btn {
      margin-top: 14px;
    }
  }
}
</style>
