<template>
  <div class="contact-us flex flex-col absolute w-full overflow-hidden">
    <div class="contact-us-header text-white text-center font-bold">
      <span class="title title-yellow"> Have any questions </span>
      <br />
      <span class="title">or need assistance?</span>
    </div>

    <p class="contact-us-description text-center opacity-60">
      Get in touch with our friendly support team
    </p>
    <button class="contact-us-btn font-medium max-w-max flex self-center" @click="showModal = true">Contact Us</button>
    
    <picture class="contact-us-bg w-full absolute top-0 left-0">
      <source media="(min-width:1290px)" srcset="../assets/contact-us/xl.svg">
      <source media="(min-width:1000px)" srcset="../assets/contact-us/lg.svg">
      <source media="(min-width:768px)" srcset="../assets/contact-us/md.svg">
      <source media="(min-width:480px)" srcset="../assets/contact-us/sm.svg">
      <img src="../assets/contact-us/xs.svg" alt="banner" class="w-full">
    </picture>
  </div>

  <ContactUsModal
    v-if="showModal"
    showModal="showModal"
    @close="showModal = false"
    @privacyHandle="openPrivacyModal()"
    @handleSuccessModal="openSuccessModal()"
  ></ContactUsModal>

  <PrivacyModal
    v-if="showPrivacyModal"
    showPrivacyModal="showPrivacyModal"
    @closePrivacy="showPrivacyModal = false"
  ></PrivacyModal>

  <SuccessModal
    v-if="showSuccessModal"
    showSuccessModal="showSuccessModal"
    @close="showSuccessModal = false"
  ></SuccessModal>
</template>

<script>
import ContactUsModal from "./ContactUsModal";
import PrivacyModal from "./PrivacyModal";
import SuccessModal from "./SuccessModal";

export default {
  name: "app-contact-us",
  components: {
    ContactUsModal,
    PrivacyModal,
    SuccessModal,
  },
  data() {
    return {
      showModal: false,
      showPrivacyModal: false,
      showSuccessModal: false,
    };
  },
  methods: {
    openPrivacyModal() {
      this.showModal = false;
      this.showPrivacyModal = true;
    },
    openSuccessModal() {
      this.showModal = false;
      this.showPrivacyModal = false;
      this.showSuccessModal = true;
    }
  }
};
</script>

<style lang="scss">
.contact-us {
  background: radial-gradient(
    235.66% 523.83% at 136.82% -37.47%,
    #2a9d8f 0%,
    #264653 100%
  );
  font-family: "Forma DJR Cyrilic Variable";
  z-index: 1;
  border-radius: 30px;
  padding: 20px;
  gap: 10px;

  &-header {
    font-size: 25px;
    line-height: 118%;
    font-family: "Forma DJR Cyrilic Bold";

    .title-yellow {
      color: #e9c46a;
    }
  }

  &-description {
    font-size: 16px;
    line-height: 23px;
    color: #f1faee;
  }

  &-btn {
    background: #1bb7a4;
    border-radius: 89px;
    font-size: 18px;
    line-height: 22px;
    color: #f1faee;
    margin-top: 10px;
    padding: 16px 32px;
    min-width: 100%;
    justify-content: center;

    &:hover {
      background: #E9C46A;
    }
  }

  &-bg {
    // background-image: url(../assets/contact-us/xs.svg);
    z-index: -1;
  }
}

@media (min-width: 480px) {
  .contact-us {
    gap: 16px;
    border-radius: 40px;

    &-header {
      font-size: 32px;
      line-height: 38px;

      .title {
        letter-spacing: 0.6px;
      }
    }

    &-btn {
      margin-top: 14px;
    }

    // &-bg {
    //   background-image: url(../assets/contact-us/sm.svg);
    // }
  }
}

@media (min-width: 768px) {
  .contact-us {
    gap: 13px;
    padding: 20px 73px;

    &-header {
      br {
        display: none;
      }
    }

    &-btn {
      margin-top: 17px;
      padding: 16px 32px;
      min-width: auto;
    }

    // &-bg {
    //   background-image: url(../assets/contact-us/md.svg);
    // }
  }
}

@media (min-width: 1000px) {
  .contact-us {
    padding: 30px 88px;
    gap: 10px;
    border-radius: 50px;

    &-header {
      font-size: 45px;
      line-height: 118%;
      width: max-content;

      .title {
        letter-spacing: normal;
      }
    }

    &-description {
      font-size: 18px;
      line-height: 28px;
    }

    &-btn {
      margin-top: 21px;
    }

    // &-bg {
    //   background-image: url(../assets/contact-us/lg.svg);
    // }
  }
}

@media (min-width: 1290px) {
  .contact-us {
    padding: 50px 122px;

    &-header {
      font-size: 60px;
    }

    &-description {
      font-size: 20px;
    }

    &-btn {
      margin-top: 15px;
    }

    // &-bg {
    //   background-image: url(../assets/contact-us/xl.svg);
    // }
  }
}
</style>
