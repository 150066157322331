<template>
  <transition name="modal">
    <div class="modal-mask" :class="modalClass" @click="$emit('close')">
      <div class="modal-wrapper" @click="$event.stopPropagation()">
        <div class="modal-container">
          <button @click="$emit('close')" class="close-btn">
            <img :src="`../assets/modal/close${getIcon()}.svg`" loading="lazy" alt="modal close icon"/>
          </button>
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "app-modal",
  props: ["modalClass", "icon"],
  methods: {
    getIcon() {
      return this.icon ? this.icon : '';
    }
  }
};
</script>
<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  display: flex;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    max-height: 747px;
    display: flex;
    align-self: auto;

    .modal-container {
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Helvetica, Arial, sans-serif;
      margin: 0;
      width: 100%;
      height: 100%;
      position: relative;
      padding: 40px 10px 20px;
      overflow: scroll;

      .close-btn {
        position: absolute;
        top: 24px;
        right: 24px;
      }
    }
  }

  &.consultant-modal {
    .modal-wrapper {
      align-self: auto;
      max-height: 948px;
      
      .modal-container {
        padding: 0px;

        .modal-content {
          &__body {
            padding: 30px 10px 20px;

            &-info {
              .title {
                font-family: "Forma DJR Cyrilic Bold";
              }
            }
          }
        }
      }
    }
  }

  &.privacy-modal {
    align-items: stretch;
  }

  &.success-modal {
    .modal-wrapper {
      width: 100vw;

      .modal-container {
        height: max-content;
        padding: 20px 10px;
      }
    }
  }
}

@media (min-width: 480px) {
  .modal-mask {
    .modal-wrapper {
      .modal-container {
        padding: 40px 20px 20px;

        .close-btn {
          top: 34px;
          right: 34px;
        }
      }
    }

    &.consultant-modal {
      .modal-wrapper {
        .modal-container {
          .modal-content__body {
            padding: 30px 20px 20px;
          }
        }
      }
    }

    &.success-modal {
      .modal-wrapper {
        .modal-container {
          padding: 34px 20px 20px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .modal-mask {
    justify-content: center;
    align-items: center;

    .modal-wrapper {
      max-width: 650px;
      
      .modal-container {
        padding: 40px 50px 30px;
        border-radius: 50px;
      }
    }

    &.consultant-modal {
      .modal-wrapper {
        .modal-container {
          .modal-content__body {
            padding: 30px 50px;
          }
        }
      }
    }

    &.success-modal {
      .modal-wrapper {
        .modal-container {
          padding: 34px 50px 20px;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .modal-mask {
    &.consultant-modal {
      .modal-wrapper {
        max-width: 800px;

        .modal-content__body {
          .form {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 35px 20px;

            &-row {
              width: 340px;

              &:nth-child(even) {
                order: 1;
              }
            }
          }

          .submit-btn {
            margin-top: 25px;
          }
        }
      }
    }
  }
}
</style>
