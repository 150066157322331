<template>
  <header id="header" :class="{ scrollable: isScrollable }">
    <div class="header flex justify-between items-center">
      <div class="logo-container">
        <img
          loading="lazy"
          src="../assets/header/logo.svg"
          v-if="!isScrollable"
          class="logo"
          alt="header logo icon"
        />
        <img
          src="../assets/header/logo-alt.svg"
          v-else
          class="logo"
          loading="lazy"
          alt="logo alternative icon"
        />
      </div>

      <button
        class="header-btn items-center justify-center"
        @click="toggleAccordion()"
        @mouseover="categoryActive = true"
        @mouseleave="categoryActive = false">
        <img
          v-if="!categoryActive"
          src="../assets/header/category.svg"
          width="18"
          height="18"
          loading="lazy"
          alt="category icon"
        />
        <img
          v-if="categoryActive"
          src="../assets/header/category-active.svg"
          width="18"
          height="18"
          loading="lazy"
          alt="category icon"
        />
      </button>

      <ul class="header-list">
        <li class="header-list__item">
          <button
            @click="navigateTo('features')"
            class="cursor-pointer header-list__item-btn">
            Features
          </button>
        </li>

        <li class="header-list__item">
          <button
            @click="navigateTo('about-us')"
            class="cursor-pointer header-list__item-btn">
            About Us
          </button>
        </li>

        <li class="header-list__item">
          <button
            @click="navigateTo('sales')"
            class="cursor-pointer header-list__item-btn">
            Sales
          </button>
        </li>

        <!-- <li class="header-list__item">
          <button
            @click="navigateTo('testimonials')"
            class="cursor-pointer header-list__item-btn">
            Testimonials
          </button>
        </li> -->

        <li class="header-list__item">
          <button
            @click="navigateTo('faq')"
            class="cursor-pointer header-list__item-btn">
            FAQ
          </button>
        </li>

        <li class="header-list__item">
          <button
            @click="navigateTo('contact-us')"
            class="cursor-pointer header-list__item-btn">
            Contact Us
          </button>
        </li>
      </ul>
    </div>
  </header>

  <VueSidePanel
    v-model="isOpen"
    lock-scroll
    width="100%"
    hide-close-btn="true"
    id="sidebar-container"
  >
    <div class="sidebar relative">
      <picture class="sidebar-picture w-full absolute top-0 left-0">
        <source media="(min-width:768px)" srcset="../assets/sidebar/lg.svg">
        <source media="(min-width:480px)" srcset="../assets/sidebar/md.svg">
        <img src="../assets/sidebar/sm.svg" alt="sidebar image" class="sidebar-picture__img w-full">
      </picture>

      <div class="sidebar-header">
        <div class="logo-container">
          <img
            src="../assets/header/logo.svg"
            class="mobile-logo"
            loading="lazy"
            alt="sidebar logo icon"
          />
          <img
            src="../assets/header/sidebar-logo.svg"
            class="tablet-logo"
            loading="lazy"
            alt="sidebar logo icon for tablet"
          />
        </div>

        <button
          class="header-btn items-center justify-center"
          @click="hide()"
          @mouseover="closeActive = true"
          @mouseleave="closeActive = false">
          <img
            v-if="!closeActive"
            src="../assets/header/close.svg"
            width="15"
            height="15"
            loading="lazy"
            alt="close icon"
          />
          <img
            v-if="closeActive"
            src="../assets/header/close-active.svg"
            width="15"
            height="15"
            loading="lazy"
            alt="close icon"
          />
        </button>
      </div>

      <ul class="sidebar-list">
        <li class="sidebar-list__item">
          <button @click="navigateTo('features')" class="cursor-pointer">Features</button>
        </li>

        <li class="sidebar-list__item">
          <button @click="navigateTo('about-us')" class="cursor-pointer">About Us</button>
        </li>

        <li class="sidebar-list__item">
          <button @click="navigateTo('sales')" class="cursor-pointer">Sales</button>
        </li>

        <!-- <li class="sidebar-list__item">
          <button @click="navigateTo('testimonials')" class="cursor-pointer">Testimonials</button>
        </li> -->

        <li class="sidebar-list__item">
          <button @click="navigateTo('faq')" class="cursor-pointer">FAQ</button>
        </li>

        <li class="sidebar-list__item">
          <button @click="navigateTo('contact-us')" class="cursor-pointer">Contact Us</button>
        </li>
      </ul>

      <hr />

      <div class="sidebar-footer">
        <div class="sidebar-footer__contacts">
          <a href="tel:+11234567891" class="sidebar-footer__contacts-item">
            +1 (123) 456 78 91
          </a>
          <a
            href="mailto:support@dialogsafe.com"
            class="sidebar-footer__contacts-item"
          >
            support@dialogsafe.com
          </a>
        </div>

        <div class="sidebar-footer__rights">2023 © All Right Reserved</div>
      </div>
    </div>
  </VueSidePanel>
</template>

<script>
import { VueSidePanel } from "vue3-side-panel";
import "vue3-side-panel/dist/vue3-side-panel.css";

export default {
  name: "app-header",
  components: {
    VueSidePanel,
  },
  data() {
    return {
      isOpen: false,
      isScrollable: window.scrollY > 65,
      categoryActive: false,
      closeActive: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    hide() {
      this.isOpen = false;
    },
    navigateTo(anchor) {
      this.$emit("scroll-to", anchor);
      this.hide();
    },
    handleScroll() {
      this.isScrollable = window.scrollY > 65;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.header {
  margin: 20px 40px 0;
  transition: transparent 50ms linear;

  &-btn {
    border: 1px solid #1bb7a4;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;

    &:hover {
      background-color: #1bb7a4;
    }
  }

  &-list {
    display: none;

    &__item {
      &-btn {
        padding: 10px 20px;
        border-radius: 72px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.15);
          color: #1bb7a4;
        }
      }
    }
  }
}

.logo {
  width: 140.25px;
  height: 30px;
}

#header {
  position: absolute;
  width: 100vw;
  top: 50px;
  z-index: 3;

  &.scrollable {
    background-color: #f1faee;
    transition: background-color 140ms linear;
    position: fixed;
    top: 0;
    box-shadow: 0px 11px 63px rgba(136, 167, 126, 0.3);

    .header {
      margin: 20px 40px;
    }
  }
}

.sidebar {
  height: 100%;
  background: #264653;
  padding: 70px 40px 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;

  &-picture {
    z-index: 0;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    .logo-container {
      .tablet-logo {
        display: none;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    z-index: 1;

    &__item {
      font-family: "Forma DJR Cyrilic Bold";
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  hr {
    width: 100vw;
    position: relative;
    left: -40px;
    border-top: 1px solid #f1faee;
    opacity: 0.2;
    z-index: 1;
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-family: "Forma DJR Cyrilic Variable";
    opacity: 0.5;
    z-index: 1;

    &__contacts {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      &-item {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #f1faee;
      }
    }

    &__rights {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
  }
}

@media (min-width: 480px) {
  .sidebar {
    gap: 30px;

    &-list {
      gap: 31px;
      align-items: start;

      &__item {
        font-size: 32px;
        line-height: 38px;
      }
    }

    hr {
      width: 100%;
      left: 0;
    }

    &-footer {
      gap: 26px;

      &__contacts {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        &-item {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .vsp-wrapper .vsp--right-side {
    max-width: 384px !important;
  }

  .sidebar {
    justify-content: start;
    padding: 70px 40px 16px 50px;

    &-header {
      .logo-container {
        .mobile-logo {
          display: none;
        }

        .tablet-logo {
          display: flex;
        }
      }
    }

    hr {
      width: 270px;
    }

    &-footer {
      gap: 20px;

      &__contacts {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      &__rights {
        display: flex;
        width: 100%;
      }
    }
  }
}

@media (min-width: 1000px) {
  #header {
    .header {
      margin: 30px 70px 0 50px;

      .logo-container {
        .logo {
          width: 187px;
          height: 40px;
        }
      }

      &-btn {
        display: none;
      }

      &-list {
        display: flex;
        gap: 10px;

        &__item {
          font-family: "Forma DJR Cyrilic Variable";
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }
      }
    }

    &.scrollable {
      .header {
        margin: 30px 70px 30px 50px;

        &-list__item {
          color: #264653;
        }
      }
    }
  }
}

@media (min-width: 1290px) {
  #header {
    .header {
      margin: 50px auto;
      max-width: 1190px;

      &-list {
        gap: 30px;
      }
    }

    &.scrollable {
      .header {
        margin: 30px auto;
      }
    }
  }
}
</style>
