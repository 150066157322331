<template>
  <Header @scroll-to="onScroll($event)" />

  <main class="main-container flex flex-col">
    <section id="banner relative">
      <Banner />
    </section>

    <section id="features">
      <Features />
    </section>

    <section id="about-us">
      <AboutUs />
    </section>

    <section id="sales">
      <Sales />
    </section>

    <section id="faq">
      <FAQ />
    </section>

    <section id="contact-us" class="relative">
      <ContactUs />
    </section>
  </main>

  <Footer />
</template>

<script>
import Header from "./components/Header";
import Banner from "./components/Banner";
import Features from "./components/Features";
import AboutUs from "./components/AboutUs";
import Sales from "./components/Sales";
import FAQ from "./components/FAQ";
import ContactUs from "./components/Contact-Us";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    Header,
    Banner,
    Features,
    AboutUs,
    Sales,
    FAQ,
    ContactUs,
    Footer,
  },
  methods: {
    onScroll(event) {
      const el = document.getElementById(event);
      el.scrollIntoView({behavior: "smooth"});
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
};
</script>

<style>
#app {
  background: #f1faee;
}

.main-container {
  padding: 50px 20px 0;
  gap: 40px;
}

#contact-us {
  height: 120px;
}

@media (min-width: 480px) {
  .main-container {
    gap: 50px;
  }

  #contact-us {
    margin-top: -20px;
  }
}

@media (min-width: 768px) {
  #contact-us {
    height: 148px;
  }
}

@media (min-width: 1290px) {
  .main-container {
    max-width: 1290px;
    margin: 0 auto;
    padding: 50px 0 0;
    gap: 70px;
  }

  #contact-us {
    height: 143px;
  }
}
</style>
