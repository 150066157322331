<template>
  <div class="picture-container relative">
    <picture class="w-full">
      <source media="(min-width:1290px)" srcset="../assets/banner/lock.svg" width="295" height="365">
      <source media="(min-width:1000px)" srcset="../assets/banner/lock.svg" width="190" height="245">
      <source media="(min-width:768px)" srcset="../assets/banner/lock.svg" width="145" height="190">
      <source media="(min-width:480px)" srcset="../assets/banner/lock.svg" width="170" height="235">
      <img src="../assets/banner/lock.svg" alt="lock image" class="banner-img__lock absolute" width="110" height="153">
    </picture>
  </div>

  <div class="banner flex flex-col text-white relative overflow-hidden">
    <picture class="banner-picture w-full absolute">
      <source media="(min-width:1290px)" srcset="../assets/banner/banner-img-xl.svg" width="1290" height="590">
      <source media="(min-width:1000px)" srcset="../assets/banner/banner-img-lg.svg" width="960" height="492">
      <source media="(min-width:768px)" srcset="../assets/banner/banner-img-md.svg" width="728" height="439">
      <source media="(min-width:480px)" srcset="../assets/banner/banner-img-sm.svg" width="440" height="881">
      <img src="../assets/banner/banner-img-xs.svg" alt="banner" class="banner-picture__img w-full" width="280" height="773">
    </picture>

    <div class="banner-info flex flex-col">
      <div class="banner-info__title font-bold">
        Welcome to <br class="first-br" />
        <span class="yellow-text relative">
          DialogSafe
          <img
            loading="lazy"
            src="../assets/banner/xs-line.svg"
            class="underline absolute left-0"
            alt="underline image"
          />
        </span>
        – <br class="second-br" />
        The Ultimate Hosted LLM Solution for Businesses
      </div>

      <div class="banner-info__description opacity-60">
        Protect your business conversations with our secure, private, and
        reliable hosted LLM (Language Learning Model) solution. Don't risk your
        sensitive data with third-party providers - choose DialogSafe for
        guaranteed privacy and safety
      </div>
    </div>

    <button class="banner-btn py-4 px-8 w-full font-medium text-lg" @click="showModal = true">
      Get Started Today
    </button>
  </div>

  <ConsultantModal
    v-if="showModal"
    showModal="showModal"
    @close="showModal = false"
    @privacyHandle="openPrivacyModal()"
  ></ConsultantModal>

  <PrivacyModal
    v-if="showPrivacyModal"
    showPrivacyModal="showPrivacyModal"
    @closePrivacy="showPrivacyModal = false"
  ></PrivacyModal>
</template>

<script>
import ConsultantModal from "./ConsultantModal";
import PrivacyModal from "./PrivacyModal";

export default {
  name: "app-banner",
  components: {
    ConsultantModal,
    PrivacyModal,
  },
  methods: {
    openPrivacyModal() {
      this.showModal = false;
      this.showPrivacyModal = true;
    }
  },
  data() {
    return {
      showModal: false,
      showPrivacyModal: false,
    }
  },
};
</script>

<style lang="scss">
.banner {
  border-radius: 30px;
  font-family: "Forma DJR Cyrilic Variable";
  gap: 20px;
  background: radial-gradient(131.16% 744.47% at 1.47% 0%, #264653 0%, #2D5C6F 100%);
  padding: 20px;
  padding-top: 108.75vw;

  &-info {
    row-gap: 10px;
    z-index: 2;

    &__title {
      font-family: 'Forma DJR Cyrilic Bold';
      font-size: 25px;
      line-height: 110.19%;

      .yellow-text {
        color: #e9c46a;

        .underline {
          max-width: 118px;
          bottom: -1px;
        }
      }
    }

    &__description {
      line-height: 23px;
    }
  }

  &-btn {
    z-index: 2;
    line-height: 22px !important;
    color: #f1faee;
    background: #1bb7a4;
    border-radius: 89px;

    &:hover {
      background: #E9C46A;
    }
  }

  &-picture {
    top: 0;
    z-index: 1;
    left: 0;

    &__img {
      border-radius: 30px;
    }
  }

  &-img__lock {
    right: -20px;
    top: 70px;
    z-index: 2;
  }
}

@media (min-width: 480px) {
  .banner-img {
    &__lock {
      top: 85px;
      right: -25px;
    }
  }

  .banner {
    border-radius: 40px;

    &-info {
      gap: 13px;

      &__title {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.6px;

        .first-br {
          display: none;
        }
      }

      &__description {
        max-width: 69.583vw;
        letter-spacing: -0.1px;
      }
    }

    &-picture {
      &__img {
        border-radius: 40px;
      }
    }
  }
}

@media (min-width: 768px) {
  .banner-img {
    &__lock {
      right: -30px;
    }
  }

  .banner {
    padding-top: 80px;
    width: 100%;

    &-info {
      &__title {
        max-width: 47vw;

        .yellow-text {
          .underline {
            width: 100%;
            max-width: inherit;
          }
        }
      }

      &__description {
        max-width: 43.489vw;
      }
    }

    &-btn {
      max-width: 204px;
    }
  }
}

@media (min-width: 1000px) {
  .banner-img {
    &__lock {
      top: 75px;
    }
  }

  .banner {
    padding: 114px 30px 30px;
    min-height: 492px;
    border-radius: 50px;

    &-info {
      gap: 10px;

      &__title {
        max-width: 50vw;
        letter-spacing: 0px;
        font-size: 45px;
        line-height: 110.19%;
      }

      &__description {
        max-width: 47.9vw;
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-picture {
      &__img {
        border-radius: 50px;
      }
    }
  }
}

@media (min-width: 1290px) {
  .banner-img {
    &__lock {
      top: 90px;
      right: -80px;
    }
  }

  .banner {
    gap: 30px;
    padding: 134px 50px 50px;

    &-info {
      &__title {
        font-size: 60px;
        max-width: 647px;
      }

      &__description {
        font-size: 20px;
        max-width: 533px;
      }
    }
  }
}
</style>
