<template>
  <div class="faq flex flex-col relative">
    <div class="faq-header flex flex-col">
      <h3 class="title font-bold">
        Most frequently <span class="green-text">asked questions</span> here
      </h3>
      <p class="description opacity-60 font-normal">
        Find answers to some of our most frequently asked questions about our
        hosted LLM solution:
      </p>
    </div>

    <div class="faq-content flex">
      <div class="faq-content__list">
        <div v-for="(item, index) in items" :key="index">
          <app-accordion class="mb-5" :uid="index">
            <template v-slot:title>
              <span class="accordion-title">{{ item.title }}</span>
            </template>
            <template v-slot:content>
              <p class="accordion-description">
                {{ item.content }}
              </p>
            </template>
          </app-accordion>
        </div>
      </div>

      <div class="faq-content__img">
        <picture>
          <source media="(min-width:1290px)" srcset="../assets/faq/faq-img.svg" width="485" height="760">
          <img src="../assets/faq/faq-img.svg" alt="faq image" width="460" height="700">
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
import AppAccordion from "./AppAccordion";

export default {
  name: "app-faq",
  components: {
    AppAccordion,
  },
  data: function () {
    return {
      items: [
        {
          title: "How can I get more information about your hosted LLM solution?",
          content: `
            We offer a complimentary consultation with our sales team. You can schedule this consultation to discuss
             your business needs, objectives, and requirements. It's our opportunity to understand your needs better
              and show you how our solution can meet them.
          `,
        },
        {
          title: "How do you price your hosted LLM solution?",
          content: `
            We create custom pricing plans based on your unique needs. This approach ensures you get the best value
             for your investment. During your free consultation, we'll discuss your requirements and provide a tailored
              pricing plan.
          `,
        },
        {
          title: "What does the onboarding process look like?",
          content: `
            We provide personalized onboarding to all our clients. Our dedicated account managers will guide you through
             the process, making it seamless and straightforward. They will assist with everything from system setup to
              initial training, ensuring a smooth transition.
          `,
        },
        {
          title: "What kind of support can we expect after onboarding?",
          content: `
            Our commitment to your success goes beyond onboarding. You'll benefit from continuous support and assistance
             from our expert team. Whether it's technical issues, user training, or system upgrades, we're here to help
              you maximize the potential of our LLM solution.
          `,
        },
        {
          title: "Can the hosted LLM solution integrate with our existing systems and tools?",
          content: `
            Absolutely. We understand that integrating new solutions into existing systems can be a challenge. That's why
             we'll work with you to ensure our LLM solution integrates seamlessly into your current setup, minimizing any
              disruption to your operations.
          `,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.faq {
  font-family: "Forma DJR Cyrilic Variable";
  gap: 30px;

  &-header {
    gap: 10px;

    .title {
      font-size: 25px;
      line-height: 118%;
      color: #1d1e21;
      font-family: "Forma DJR Cyrilic Bold";

      .green-text {
        color: #1bb7a4;
      }
    }

    .description {
      font-size: 16px;
      line-height: 23px;
      color: #264653;
    }
  }

  &-content {
    &__list {
      width: 100%;
    }

    &__img {
      display: none;
    }
  }
}

.accordion {
  font-family: "Forma DJR Cyrilic Variable";

  &-title {
    font-family: "Forma DJR Cyrilic Bold";
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1d1e21;
    text-align: start;
    flex-wrap: wrap;
    display: flex;
    max-width: 167px;
  }

  &-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #264653;
    opacity: 0.6;
    letter-spacing: -0.2px;
  }
}

@media (min-width: 480px) {
  .faq {
    &-header {
      gap: 16px;

      .title {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.6px;
      }

      .description {
        letter-spacing: -0.1px;
      }
    }
  }

  .accordion {
    padding: 4px 6px 6px 20px;

    &-title {
      font-size: 20px;
      line-height: 24px;
      max-width: 320px;
      letter-spacing: 0.3px;
    }

    &-description {
      letter-spacing: -0.15px;
      max-width: 85vw;
    }
  }
}

@media (min-width: 768px) {
  .faq {
    &-header {
      gap: 13px;
    }
  }

  .accordion {
    &-title {
      max-width: fit-content;
    }

    &-description {
      max-width: fit-content;
      letter-spacing: -0.1px;
    }
  }
}

@media (min-width: 1000px) {
  .faq {
    &-header {
      gap: 10px;

      .title {
        font-size: 45px;
        line-height: 118%;
        letter-spacing: normal;
      }

      .description {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-content {
      gap: 36px;
      margin-bottom: 20px;

      &__list {
        width: 47vw;
        min-height: 697px;
      }

      &__img {
        display: flex;

        img {
          position: relative;
          top: -20px;
        }
      }
    }
  }

  .accordion {
    &-description {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

@media (min-width: 1290px) {
  .faq {
    &-header {
      .title {
        font-size: 60px;
        line-height: 118%;
      }

      .description {
        font-size: 20px;
      }
    }

    &-content {
      gap: 71px;
      margin: 0;

      &__list {
        width: 38.542vw;
        min-height: 612px;
      }

      &__img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .accordion {
    &-description {
      font-size: 20px;
    }
  }
}
</style>
