<template>
  <Modal v-if="showModal" @close="$emit('close')">
    <template v-slot:body>
      <div class="modal-content flex flex-col">
        <div class="modal-content__header flex flex-col">
          <h3 class="title font-bold">Contact us</h3>
          <p class="description font-normal opacity-50">
            Leave your details and we will contact you to discussing how
            DialogSafe can help you achieve secure and efficient business
            communications
          </p>
        </div>

        <form class="form flex flex-col">
          <div class="form-row flex flex-col relative">
            <label
              for="username"
              class="inp overflow-hidden"
              :class="{
                valid: isRequired == true,
                invalid: isRequired == false,
              }"
            >
              <input
                type="text"
                id="username"
                placeholder="&nbsp;"
                v-model="username"
              />
              <span class="label absolute">Your name</span>
              <span class="focus-bg absolute top-0 left-0 w-full h-full"></span>
              <img
                loading="lazy"
                src="../assets/modal/valid-icon.svg"
                class="valid-icon absolute"
                v-if="isRequired == true"
                alt="valid icon"
              />
              <img
                loading="lazy"
                src="../assets/modal/invalid-icon.svg"
                class="valid-icon absolute"
                v-if="isRequired == false"
                alt="invalid icon"
              />
              <span v-if="!isRequired && startValidation" class="err-msg absolute">
                Username is required
              </span>
            </label>
          </div>

          <div class="form-row flex flex-col relative">
            <label
              for="phone"
              class="inp overflow-hidden"
              :class="{
                valid: isValidPhone == true,
                invalid: isValidPhone == false,
              }"
            >
              <input
                type="tel"
                id="phone"
                placeholder="&nbsp;"
                v-model="phone"
                v-mask="'+# (###) ###-####'"
                :masked="true"
              />
              <span class="label absolute">Phone Number</span>
              <span class="focus-bg absolute top-0 left-0 w-full h-full"></span>
              <img
                loading="lazy"
                src="../assets/modal/valid-icon.svg"
                class="valid-icon absolute"
                v-if="isValidPhone == true"
                alt="valid icon"
              />
              <img
                loading="lazy"
                src="../assets/modal/invalid-icon.svg"
                class="valid-icon absolute"
                v-if="isValidPhone == false"
                alt="invalid icon"
              />
              <span v-if="!isValidPhone && startValidation" class="err-msg absolute">
                Invalid number format
              </span>
            </label>
          </div>

          <div class="form-row flex flex-col relative">
            <label
              for="email"
              class="inp overflow-hidden"
              :class="{
                valid: isValidEmail == true,
                invalid: isValidEmail == false,
              }"
            >
              <input
                type="email"
                id="email"
                placeholder="&nbsp;"
                v-model="email"
              />
              <span class="label absolute">Email Address</span>
              <span class="focus-bg absolute top-0 left-0 w-full h-full"></span>
              <img
                loading="lazy"
                src="../assets/modal/valid-icon.svg"
                class="valid-icon absolute"
                v-if="isValidEmail == true"
                alt="valid icon"
              />
              <img
                loading="lazy"
                src="../assets/modal/invalid-icon.svg"
                class="valid-icon absolute"
                v-if="isValidEmail == false"
                alt="invalid icon"
              />
              <span v-if="!isValidEmail && startValidation" class="err-msg absolute">
                Invalid email format
              </span>
            </label>
          </div>
        </form>

        <div class="contact-form flex flex-col">
          <p class="contact-form__title opacity-50 font-normal">
            What is the best way to contact you?
          </p>
          <div class="checkbox-group flex items-center flex-wrap">
            <div class="checkbox-group__item flex items-center">
              <input
                type="checkbox"
                id="phone"
                value="Phone"
                v-model="checkedNames"
                class="opacity-10"
              />
              <label for="phone" class="font-normal">Phone</label>
            </div>

            <div class="checkbox-group__item flex items-center">
              <input
                type="checkbox"
                id="email"
                value="Email"
                v-model="checkedNames"
                class="opacity-10"
              />
              <label for="email" class="font-normal">Email</label>
            </div>

            <div class="checkbox-group__item flex items-center">
              <input
                type="checkbox"
                id="messenger"
                value="Messenger"
                v-model="checkedNames"
                class="opacity-10"
              />
              <label for="messenger" class="font-normal">Messenger</label>
            </div>
          </div>
        </div>

        <button class="submit-btn font-medium" @click="handleContact()">Submit</button>

        <p class="privacy font-light text-center">
          By clicking the «Submit» button, you agree to the <a class="privacy-link" @click="$emit('privacyHandle')">privacy policy</a>
        </p>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "./Modal";
import {mask} from 'vue-the-mask'

export default {
  name: "contact-us-modal",
  components: {
    Modal,
  },
  directives: {mask},
  data: function () {
    return {
      checkedNames: [],
      username: "",
      email: "",
      phone: "",
      startValidation: false,
    };
  },
  methods: {
    handleContact: function () {
      this.startValidation = true;
      this.sendEmail();
    },
    sendEmail: function () {
      this.$loadScript("https://smtpjs.com/v3/smtp.js")
        .then(() => {
          const body = `username: ${this.username}<br/>email: ${this.email};<br/>phone: ${this.phone};<br/>
            The best way to contact me: ${this.checkedNames.join()}`;

          window.Email && window.Email.send({
            SecureToken : "64ab2019-e762-46f3-b21c-7ea78ac5d614",
            To : 'support@dialogsafe.com',
            From : 'support@dialogsafe.com',
            Subject : "Contact with me",
            Body : `Please, contact with me <br/> ${body}`
          }).then(message => {
            console.log('message', message);
            this.$emit('handleSuccessModal');
          });
        })
        .catch(() => {});
    }
  },
  props: ["showModal"],
  computed: {
    isValidEmail() {
      // eslint-disable-next-line no-useless-escape
      return this.startValidation ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email) : null;
    },
    isValidPhone() {
      // eslint-disable-next-line no-useless-escape
      return this.startValidation ? !!this.phone : null;
    },
    isRequired() {
      return this.startValidation ? !!this.username : null;
    },
  },
};
</script>
<style lang="scss">
.modal-content {
  gap: 20px;
  font-family: "Forma DJR Cyrilic Variable";

  &__header {
    gap: 10px;
    color: #1d1e21;

    .title {
      font-size: 20px;
      line-height: 24px;
      font-family: "Forma DJR Cyrilic Bold";
    }

    .description {
      font-size: 16px;
      line-height: 23px;
    }
  }

  .form {
    gap: 40px;
    margin-top: 13px;
    font-family: "Forma DJR Cyrilic Variable";

    &-row {
      gap: 5px;
    }
  }

  .contact-form {
    margin-top: 20px;
    gap: 10px;

    &__title {
      font-size: 16px;
      line-height: 23px;
      color: #1d1e21;
    }

    .checkbox-group {
      gap: 20px 30px;

      &__item {
        gap: 10px;

        input {
          width: 24px;
          height: 24px;
          border: 1px solid #1d1e21;
          border-radius: 5px;

          &:checked {
            border: 1px solid #68d336;
            // background-color: white;
          }
        }

        label {
          font-family: "Forma DJR Cyrilic Variable";
          font-size: 20px;
          line-height: 28px;
          color: #1d1e21;
        }
      }
    }
  }

  .privacy {
    font-family: "Forma DJR Cyrilic Variable";
    font-size: 14px;
    line-height: 17px;
    color: #949494;
    max-width: 62.5vw;
    margin: auto;

    &-link {
      color: #0047ff;
      cursor: pointer;
    }
  }

  .submit-btn {
    background: #1bb7a4;
    border-radius: 89px;
    color: #f1faee;
    font-size: 18px;
    line-height: 22px;
    font-family: "Forma DJR Cyrilic Variable";
    padding: 16px 32px;
  }
}

.inp {
  border-radius: 50px;

  .label {
    top: 16px;
    left: 20px;
    font-size: 16px;
    color: rgba(#1d1e21, 0.5);
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
    pointer-events: none;
  }

  .focus-bg {
    background: rgba(#1d1e21, 0.05);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    padding: 13px 20px 12px;
    background: rgba(29, 30, 33, 0.02);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #1d1e21;
    height: 54px;
    transition: all 0.15s ease;

    &:not(:placeholder-shown) {
      + .label {
        transform: translate3d(0, -35px, 0) scale(0.75);
        font-size: 12px;
        line-height: 28px;
        color: #1d1e21;
        opacity: 0.2;
      }
    }

    &:focus {
      + .label {
        transform: translate3d(0, -35px, 0) scale(0.75);
        font-size: 12px;
        line-height: 28px;
        color: #1d1e21;
        opacity: 0.2;

        + .focus-bg {
          transform: scaleX(1);
          transition: all 0.1s ease;
        }
      }
    }

    &:focus-visible {
      outline: none;
    }
  }

  &.valid {
    border: 1px solid #68d336;
  }

  &.invalid {
    border: 1px solid #e11a1a;

    input {
      color: #e11a1a;
    }
  }

  .valid-icon {
    top: 18.25px;
    right: 22.75px;
  }

  .err-msg {
    top: 53px;
    left: 20px;
    color: #e11a1a;
    font-size: 14px;
    line-height: 28px;
    font-family: "Forma DJR Cyrilic Variable";
  }
}

@media (min-width: 480px) {
  .modal-content {
    gap: 30px;

    &__header {
      .title {
        font-size: 32px;
        line-height: 38px;
      }
    }

    .privacy {
      max-width: 81vw;
    }
  }
}

@media (min-width: 768px) {
  .modal-content {
    .privacy {
      max-width: max-content;
    }
  }
}
</style>
