<template>
  <Modal v-if="showModal" @close="$emit('close')" :modalClass="'consultant-modal'" :icon="'white'">
    <template v-slot:body>
      <div class="modal-content">
        <div class="modal-content__header">
        </div>
        <div class="modal-content__body flex flex-col">
          <div class="modal-content__body-info flex flex-col">
            <h3 class="title font-bold">Request for a consultation</h3>
            <p class="description opacity-50">
              Provide us with some basic information about your business and your contact details so we can understand
               your needs better and get in touch with you
            </p>
          </div>

          <form class="form flex flex-col">
            <div class="form-row flex flex-col relative">
              <label
                for="username"
                class="inp overflow-hidden"
                :class="{
                  valid: isRequired == true,
                  invalid: isRequired == false,
                }"
              >
                <input
                  type="text"
                  id="username"
                  placeholder="&nbsp;"
                  v-model="username"
                />
                <span class="label absolute">Your name</span>
                <span class="focus-bg absolute top-0 left-0 w-full h-full"></span>
                <img
                  loading="lazy"
                  src="../assets/modal/valid-icon.svg"
                  class="valid-icon absolute"
                  v-if="isRequired == true"
                  alt="valid icon"
                />
                <img
                  loading="lazy"
                  src="../assets/modal/invalid-icon.svg"
                  class="valid-icon absolute"
                  v-if="isRequired == false"
                  alt="invalid icon"
                />
                <span v-if="!isRequired && startValidation" class="err-msg absolute">
                  Username is required
                </span>
              </label>
            </div>

            <div class="form-row flex flex-col relative">
              <label
                for="phone"
                class="inp overflow-hidden"
                :class="{
                  valid: isValidPhone == true,
                  invalid: isValidPhone == false,
                }"
              >
                <input
                  type="tel"
                  id="phone"
                  placeholder="&nbsp;"
                  v-model="phone"
                  v-mask="'+# (###) ###-####'"
                  :masked="true"
                />
                <span class="label absolute">Phone Number</span>
                <span class="focus-bg absolute top-0 left-0 w-full h-full"></span>
                <img
                  loading="lazy"
                  src="../assets/modal/valid-icon.svg"
                  class="valid-icon absolute"
                  v-if="isValidPhone == true"
                  alt="valid icon"
                />
                <img
                  loading="lazy"
                  src="../assets/modal/invalid-icon.svg"
                  class="valid-icon absolute"
                  v-if="isValidPhone == false"
                  alt="invalid icon"
                />
                <span v-if="!isValidPhone && startValidation" class="err-msg absolute">
                  Invalid number format
                </span>
              </label>
            </div>
            
            <div class="form-row flex flex-col relative">
              <label
                for="company-name"
                class="inp overflow-hidden"
                :class="{
                  valid: isRequiredCompany == true,
                  invalid: isRequiredCompany == false,
                }"
              >
                <input
                  type="text"
                  id="company-name"
                  placeholder="&nbsp;"
                  v-model="companyName"
                />
                <span class="label absolute">Company name</span>
                <span class="focus-bg absolute top-0 left-0 w-full h-full"></span>
                <img
                  loading="lazy"
                  src="../assets/modal/valid-icon.svg"
                  class="valid-icon absolute"
                  v-if="isRequiredCompany == true"
                  alt="valid icon"
                />
                <img
                  loading="lazy"
                  src="../assets/modal/invalid-icon.svg"
                  class="valid-icon absolute"
                  v-if="isRequiredCompany == false"
                  alt="invalid icon"
                />
                <span v-if="!isRequiredCompany && startValidation" class="err-msg absolute">
                  Company name is required
                </span>
              </label>
            </div>

            <div class="form-row flex flex-col relative">
              <label
                for="email"
                class="inp overflow-hidden"
                :class="{
                  valid: isValidEmail == true,
                  invalid: isValidEmail == false,
                }"
              >
                <input
                  type="email"
                  id="email"
                  placeholder="&nbsp;"
                  v-model="email"
                />
                <span class="label absolute">Email Address</span>
                <span class="focus-bg absolute top-0 left-0 w-full h-full"></span>
                <img
                  loading="lazy"
                  src="../assets/modal/valid-icon.svg"
                  class="valid-icon absolute"
                  v-if="isValidEmail == true"
                  alt="valid icon"
                />
                <img
                  loading="lazy"
                  src="../assets/modal/invalid-icon.svg"
                  class="valid-icon absolute"
                  v-if="isValidEmail == false"
                  alt="invalid icon"
                />
                <span v-if="!isValidEmail && startValidation" class="err-msg absolute">
                  Invalid email format
                </span>
              </label>
            </div>
          </form>

          <div class="modal-content__body-footer flex flex-col">
            <button class="submit-btn font-medium" @click="handleConsultant()">Submit Consultation Request</button>

            <p class="privacy font-light text-center">
              By clicking the Submit Request for Consultation button, you agree to 
              the <a class="privacy-link" @click="$emit('privacyHandle')">privacy policy</a>
            </p>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "./Modal";
import {mask} from 'vue-the-mask'

export default {
  name: "consultant-modal",
  components: {
    Modal,
  },
  directives: {mask},
  data: function () {
    return {
      username: "",
      email: "",
      phone: "",
      companyName: "",
      startValidation: false,
    };
  },
  methods: {
    handleConsultant: function () {
      this.startValidation = true;
      this.sendEmail();
    },
    sendEmail: function() {
      this.$loadScript("https://smtpjs.com/v3/smtp.js")
        .then(() => {
          const body = `username: ${this.username}<br/>email: ${this.email};<br/>phone: ${this.phone};<br/>
            company name: ${this.companyName}`;

          window.Email && window.Email.send({
            SecureToken : "64ab2019-e762-46f3-b21c-7ea78ac5d614",
            To : 'support@dialogsafe.com',
            From : 'support@dialogsafe.com',
            Subject : "Request for a consultation",
            Body : `Please, contact with me <br/> ${body}`
          }).then(message => {
            console.log('message', message);
            this.$emit('handleSuccessModal');
          });
        })
        .catch(() => {});
    }
  },
  props: ["showModal"],
  computed: {
    isValidEmail() {
      // eslint-disable-next-line no-useless-escape
      return this.startValidation ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email) : null;
    },
    isValidPhone() {
      // eslint-disable-next-line no-useless-escape
      return this.startValidation ? !!this.phone : null;
    },
    isRequired() {
      return this.startValidation ? !!this.username : null;
    },
    isRequiredCompany() {
      return this.startValidation ? !!this.companyName : null;
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-content {
  &__header {
    background-image: url('../assets/modal/consultant-img-sm.svg');
    width: 100%;
    max-width: 378px;
    min-width: 320px;
    height: 183px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &__body {
    padding: 10px 10px 20px;
    gap: 20px;

    &-info {
      gap: 10px;
      font-family: 'Forma DJR Cyrilic Variable';

      .title {
        font-size: 32px;
        line-height: 38px;
        color: #1D1E21;
      }

      .description {
        font-size: 16px;
        line-height: 23px;
        color: #1D1E21;
      }
    }

    .form {
      margin-top: 23px;
      gap: 40px;
    }

    .submit-btn {
      margin-top: 30px;
      padding: 16px 23.5px;
    }

    &-footer {
      gap: 20px;
    }
  }

   .privacy {
    font-family: "Forma DJR Cyrilic Variable";
    font-size: 14px;
    line-height: 17px;
    color: #949494;
    max-width: 62.5vw;
    margin: auto;

    &-link {
      color: #0047ff;
      cursor: pointer;
    }
  }
}

.inp {
  border-radius: 50px;

  .label {
    top: 16px;
    left: 20px;
    font-size: 16px;
    color: rgba(#1d1e21, 0.5);
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
    pointer-events: none;
  }

  .focus-bg {
    background: rgba(#1d1e21, 0.05);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    padding: 13px 20px 12px;
    background: rgba(29, 30, 33, 0.02);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #1d1e21;
    height: 54px;
    transition: all 0.15s ease;

    &:not(:placeholder-shown) {
      + .label {
        transform: translate3d(0, -35px, 0) scale(0.75);
        font-size: 12px;
        line-height: 28px;
        color: #1d1e21;
        opacity: 0.2;
      }
    }

    &:focus {
      + .label {
        transform: translate3d(0, -35px, 0) scale(0.75);
        font-size: 12px;
        line-height: 28px;
        color: #1d1e21;
        opacity: 0.2;

        + .focus-bg {
          transform: scaleX(1);
          transition: all 0.1s ease;
        }
      }
    }

    &:focus-visible {
      outline: none;
    }
  }

  &.valid {
    border: 1px solid #68d336;
  }

  &.invalid {
    border: 1px solid #e11a1a;

    input {
      color: #e11a1a;
    }
  }

  .valid-icon {
    top: 18.25px;
    right: 22.75px;
  }

  .err-msg {
    top: 53px;
    left: 20px;
    color: #e11a1a;
    font-size: 14px;
    line-height: 28px;
    font-family: "Forma DJR Cyrilic Variable";
  }
}

@media (min-width: 480px) {
  .modal-content {
    &__header {
      background-image: url('../assets/modal/consultant-img-md.svg');
      min-width: 480px;
      height: 213px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }

    &__body {
      padding: 30px 20px 20px;
    }
  }
}

@media (min-width: 768px) {
  .modal-content {
    &__header {
      background-image: url('../assets/modal/consultant-img.svg');
      height: 246px;
      min-width: 650px;
      max-width: 650px;
    }
  }
}

@media (min-width: 1000px) {
  .modal-content {
    &__header {
      background-image: url('../assets/modal/consultant-img-lg.svg');
      min-width: 800px;
      height: 264px;
    }

    &__body {
      &-info {
        .description {
          font-size: 20px;
          line-height: 28px;
        }
      }

      &-footer {
        flex-direction: row;
        gap: 30px;
        align-items: center;
        margin-top: 25px;

        .submit-btn {
          min-width: 288px;
          margin: 0 !important;
        }

        .privacy {
          margin: 0;
          text-align: start;
        }
      }
    }
  }
}
</style>