<template>
  <div class="features flex flex-col">
    <h2 class="features-title font-bold">
      <span class="green-text">Features</span> of our product
    </h2>
    <ul class="features-list flex">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="features-list__item flex flex-col"
      >
        <div class="icon-container flex items-center justify-center">
          <img
            :src="`/assets/features/${item.icon}.svg`"
            class="icon"
            :class="item.icon"
            loading="lazy"
            :alt="`${item.icon} icon`"
            :width="item.width"
            :height="item.height"
          />
        </div>
        <h3 class="title font-bold">{{ item.title }}</h3>
        <p class="description font-normal">{{ item.description }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "app-features",
  data: function () {
    return {
      items: [
        {
          icon: "lock",
          title: "End-to-end encryption",
          description: `
            Keep your business communications secure with our end-to-end encryption technology, ensuring that only
             authorized users can access your conversations.
          `,
          width: "22",
          height: "25.88",
        },
        {
          icon: "star",
          title: "Customizable language models",
          description: `
            Train and deploy custom language models tailored to your specific industry and use case, improving the
             efficiency of your business communications.
          `,
          width: "25.88",
          height: "24.59",
        },
        {
          icon: "paper",
          title: "Data privacy compliance",
          description: `
            Our hosted LLM solution is designed to comply with major data privacy regulations like GDPR and CCPA,
             safeguarding your business from potential fines and legal issues.
          `,
          width: "22",
          height: "25.88",
        },
        {
          icon: "clock",
          title: "24/7 support",
          description: `
            Our dedicated support team is available around the clock to assist you with any questions or issues,
             ensuring the smooth operation of your LLM services.
          `,
          width: "25.88",
          height: "25.88",
        },
        {
          icon: "setting",
          title: "Easy integration",
          description: `
            Seamlessly integrate our LLM solution with your existing systems and tools, thanks to our user-friendly
             API and thorough documentation.
          `,
          width: "24.59",
          height: "25.88",
        },
        {
          icon: "category",
          title: "Scalable infrastructure",
          description: `
            Our robust and scalable infrastructure can handle businesses of all sizes, from startups to enterprises,
             ensuring optimal performance for your LLM applications.
          `,
          width: "25.88",
          height: "25.88",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.features {
  gap: 20px;
  font-family: "Forma DJR Cyrilic Variable";

  &-title {
    font-family: "Forma DJR Cyrilic Bold";
    font-size: 25px;
    line-height: 118%;
    color: #1d1e21;

    .green-text {
      color: #1bb7a4;
    }
  }

  &-list {
    gap: 20px;
    flex-direction: column;

    &__item {
      gap: 10px;

      .icon-container {
        width: 48px;
        height: 48px;
        border: 1px solid #1bb7a4;
        border-radius: 10px;
      }

      .title {
        font-size: 20px;
        line-height: 24px;
        color: #1d1e21;
        max-width: 265px;
        font-family: "Forma DJR Cyrilic Bold";
      }

      .description {
        font-size: 16px;
        line-height: 23px;
        color: #264653;
        min-width: 285px;
      }

      &.protection {
        gap: 13px;
      }
    }
  }
}

@media (min-width: 480px) {
  .features {
    gap: 35px;

    &-title {
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 0.6px;
    }

    &-list {
      flex-wrap: wrap;
      flex-direction: row;

      &__item {
        max-width: calc((100vw - 60px) / 2);

        .title {
          max-width: 75%;
          letter-spacing: 0.5px;
        }

        .description {
          padding-top: 10px;
          min-width: auto;
          letter-spacing: -0.15px;
        }

        &:nth-child(5) {
          order: 1;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .features {
    &-list {
      gap: 30px 20px;

      &__item {
        max-width: calc((100vw - 80px) / 3);

        &:first-child {
          .title {
            max-width: 88%;
          }
        }

        .title {
          letter-spacing: 0.5px;
          max-width: 98%;
        }

        .description {
          letter-spacing: -0.1px;
        }

        &:nth-child(4) {
          order: 2;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .features {
    gap: 20px;

    &-title {
      font-size: 45px;
      line-height: 118%;
      letter-spacing: 0;
    }

    &-list {
      &__item {
        .icon-container {
          width: 68px;
          height: 68px;

          .icon {
            &.lock,
            &.paper {
              width: 31.17px;
              height: 36.67px;
            }

            &.star {
              width: 36.67px;
              height: 34.83px;
            }

            &.category,
            &.clock {
              width: 36.67px;
              height: 36.67px;
            }

            &.setting {
              width: 34.83px;
              height: 36.67px;
            }
          }
        }

        .title {
          font-size: 32px;
          line-height: 38px;
        }

        .description {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}

@media (min-width: 1290px) {
  .features {
    gap: 30px;

    &-title {
      font-size: 60px;
      line-height: 118%;
    }

    &-list {
      gap: 40px;

      &__item {
        max-width: calc((100% - 80px) / 3);

        .description {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
