<template>
  <div class="accordion" :class="{ open: isOpen }" v-outside="closeEvent">
    <button
      @click="toggleAccordion()"
      class="flex items-center space-x-3 accordion-btn justify-between w-full"
      :aria-expanded="isOpen"
      :aria-controls="`collapse-${uid}`"
    >
      <slot name="title" />
      <div class="icon-container flex justify-center items-center">
        <svg
          class="transition-all duration-200 transform"
          :class="{
            'rotate-180': isOpen,
            'rotate-0': !isOpen,
          }"
          fill="none"
          stroke="#FFFFFF"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 10"
          aria-hidden="true"
        >
          <path
            d="M15 1.2l-7 7-7-7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </button>

    <div v-show="isOpen" :id="`collapse-${uid}`">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
const outside = {
  priority: 700,
  bind() {
    let self = this;
    this.event = function (event) {
      console.log("emitting event");
      self.vm.$emit(self.expression, event);
    };
    this.el.addEventListener("click", this.stopProp);
    document.body.addEventListener("click", this.event);
  },

  unbind() {
    console.log("unbind");
    this.el.removeEventListener("click", this.stopProp);
    document.body.removeEventListener("click", this.event);
  },
  stopProp(event) {
    event.stopPropagation();
  },
};

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    hide() {
      this.isOpen = false;
    },
  },
  events: {
    closeEvent: function () {
      console.log("close event called");
      this.hide();
    },
  },
  directives: {
    outside,
  },
  props: ['uid']
};
</script>
<style lang="scss">
.accordion {
  border: 1px solid rgba(38, 70, 83, 0.1);
  border-radius: 50px;
  padding: 6px 6px 6px 20px;

  &.open {
    border-radius: 36px;
    padding: 6px 6px 20px 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  &-btn {
    gap: 30px;

    .icon-container {
      margin: 0 !important;
      min-width: 48px;
      height: 48px;
      background-color: #1bb7a4;
      border-radius: 50%;

      svg {
        height: 7.87px;
        width: 16.52px;
        top: 0;
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &:hover {
        background: #264653;
      }
    }
  }
}

@media (min-width: 480px) {
  .accordion {
    &-btn {
      .icon-container {
        min-width: 61px;
        min-height: 61px;

        svg {
          min-height: 10px;
          min-width: 21px;
        }
      }
    }
  }
}
</style>
