<template>
  <div class="about-us relative">
    <picture class="about-us-picture w-full absolute">
      <source
        media="(min-width:1290px)"
        srcset="../assets/about-us/1920.svg"
        width="1290"
        height="1550"
      />
      <source
        media="(min-width:1000px)"
        srcset="../assets/about-us/1000.svg"
        width="960"
        height="1446"
      />
      <source
        media="(min-width:768px)"
        srcset="../assets/about-us/768.svg"
        width="728"
        height="1261"
      />
      <source
        media="(min-width:480px)"
        srcset="../assets/about-us/480.svg"
        width="440"
        height="2149"
      />
      <img
        src="../assets/about-us/320.svg"
        alt="about-us background"
        class="about-us-picture__img w-full"
        width="280"
        height="2248"
      />
    </picture>

    <h3 class="about-us__title font-bold text-white text-center relative">
      <span class="yellow-color">About us</span> and our work
    </h3>
    <ul class="about-us__list flex flex-col relative">
      <li class="about-us__list-item professionals">
        <div class="item-img__container relative">
          <picture>
            <source
              media="(min-width:1290px)"
              srcset="../assets/about-us/professionals-lg.svg"
              width="539.55"
              height="545.43"
            />

            <source
              media="(min-width:1000px)"
              srcset="../assets/about-us/professionals-bg.svg"
              width="413.38"
              height="482.09"
            />

            <source
              media="(min-width:768px)"
              srcset="../assets/about-us/professionals-bg.svg"
              width="334"
              height="389.51"
            />

            <source
              media="(min-width:480px)"
              srcset="../assets/about-us/professionals.svg"
              width="401.71"
              height="363.45"
            />

            <img
              src="../assets/about-us/professionals.svg"
              alt="professional image"
              class="item-img relative"
              loading="lazy"
              width="240"
              height="217.14"
            />
          </picture>
        </div>
        <div class="item-description">
          <div class="icon-container">
            <img
              loading="lazy"
              src="../assets/about-us/professionals-icon.svg"
              class="item-icon"
              alt="professionals icon"
            />
          </div>
          <h4 class="item-title">We are professionals</h4>
          <div class="description-container">
            <p class="description">
              DialogSafe was established by a diverse and talented group of
              professionals specializing in artificial intelligence, data
              privacy, and business communications. Our mission is to empower
              businesses with a secure, reliable, and privacy-focused LLM
              solution that boosts their productivity and streamlines their
              operations.
            </p>
            <br />
            <p class="description">
              With decades of combined experience in the AI and tech industries,
              our founders recognized the growing need for a dedicated LLM
              solution that prioritizes data protection and compliance. As a
              result, DialogSafe was born, combining cutting-edge technology
              with a strong commitment to safeguarding our clients' sensitive
              information.
            </p>
          </div>
        </div>
      </li>
      <li class="about-us__list-item protection">
        <div class="icon-container">
          <div class="item-img__container relative">
            <picture>
              <source
                media="(min-width: 1290px)"
                srcset="../assets/about-us/protection.svg"
                width="568.54"
                height="450.74"
              />
              <source
                media="(min-width: 1000px)"
                srcset="../assets/about-us/protection.svg"
                width="440"
                height="348.84"
              />
              <source
                media="(min-width: 768px)"
                srcset="../assets/about-us/protection.svg"
                width="334"
                height="264.8"
              />
              <source
                media="(min-width: 480px)"
                srcset="../assets/about-us/protection.svg"
                width="400"
                height="317.12"
              />
              <img
                src="../assets/about-us/protection.svg"
                alt="protection img"
                width="240"
                height="190.27"
              />
            </picture>
          </div>
        </div>
        <div class="item-description">
          <div class="icon-container">
            <img
              loading="lazy"
              src="../assets/about-us/protection-icon.svg"
              class="item-icon"
              alt="protection icon"
            />
          </div>
          <h4 class="item-title">The highest level</h4>
          <div class="description-container">
            <p class="description">
              We pride ourselves on offering a comprehensive LLM platform that
              caters to businesses across various industries, from healthcare
              and finance to retail and education. Our team of dedicated
              engineers and data scientists continuously work to enhance our
              platform, ensuring it remains at the forefront of AI innovation
              while maintaining the highest levels of security and privacy.
            </p>
          </div>
        </div>
      </li>
      <li class="about-us__list-item confidence">
        <div class="icon-container">
          <div class="item-img__container relative">
            <picture>
              <source
                media="(min-width: 1290px)"
                srcset="../assets/about-us/confidence.svg"
                width="506"
                height="550.03"
              />
              <source
                media="(min-width: 1000px)"
                srcset="../assets/about-us/confidence.svg"
                width="395.46"
                height="429.86"
              />
              <source
                media="(min-width: 480px)"
                srcset="../assets/about-us/confidence.svg"
                width="316"
                height="343.49"
              />
              <img
                src="../assets/about-us/confidence.svg"
                alt="protection img"
                width="240"
                height="260.88"
              />
            </picture>
          </div>
        </div>
        <div class="item-description">
          <div class="icon-container">
            <img
              loading="lazy"
              src="../assets/about-us/confidence-icon.svg"
              class="item-icon"
              alt="confidence icon"
            />
          </div>
          <h4 class="item-title">Confidence</h4>
          <div class="description-container">
            <p class="description">
              At DialogSafe, we understand that trust is paramount in today's
              digital landscape. That's why we've built a culture of
              transparency and integrity, fostering strong relationships with
              our clients and partners. Our unwavering commitment to customer satisfaction, along with our
              deep expertise in AI and data privacy, makes us the go-to choice
              for businesses seeking a reliable and secure LLM solution.
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "app-about-us",
};
</script>

<style lang="scss">
.about-us {
  background: radial-gradient(
    154.11% 148.63% at 150.78% -46.33%,
    #1bb7a4 0%,
    #274a58 80.88%
  );
  padding: 20px 18px 30px 19px;
  font-family: "Forma DJR Cyrilic Variable";
  border-radius: 30px;

  &__title {
    font-family: "Forma DJR Cyrilic Bold";
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 118.19%;
    z-index: 2;

    .yellow-color {
      color: #e9c46a;
    }
  }

  &__list {
    gap: 30px;
    z-index: 2;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 19.86px;

      // .item-img__container {
      //   display: flex;
      //   align-self: center;
      //   justify-content: center;
      // }

      // .item-img {
      //   width: 242px;
      //   object-fit: cover;
      //   display: flex;
      //   align-self: center;
      //   z-index: 2;
      // }

      // .teal-shadow {
      //   mix-blend-mode: lighten;
      //   filter: blur(80.7139px);
      //   position: absolute;
      //   top: -5px;
      //   z-index: 1;
      //   left: -19px;
      //   width: 267.51px;
      //   height: 267.51px;
      // }

      .item-description {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .icon-container {
          border: 1px solid #1bb7a4;
          border-radius: 10px;
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;

          .item-icon {
            width: 28.47px;
            height: 20.71px;
          }
        }

        .item-title {
          font-family: "Forma DJR Cyrilic Bold";
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
          letter-spacing: 0.7px;
        }

        .description-container {
          .description {
            font-family: "Forma DJR Cyrilic Variable";
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #ffffff;
            opacity: 0.5;
          }
        }
      }

      // &.professionals {
      //   .item-img__container {
      //     .md,
      //     .lg {
      //       display: none;
      //     }
      //   }
      // }

      &.protection {
        gap: 13px;

        .item-description {
          .icon-container {
            border: 1px solid #bad2ff;
          }
        }
      }

      &.confidence {
        // margin-top: -4.289px;
        gap: 25.81px;

        .item-description {
          .icon-container {
            border: 1px solid #ffbaec;
          }
        }
      }
    }
  }

  &-picture {
    top: 0;
    z-index: 1;
    left: 0;

    &__img {
      border-radius: 30px;
    }
  }
}

@media (min-width: 480px) {
  .about-us {
    border-radius: 40px;
    padding: 20px 18px 20px 19px;

    &__title {
      margin-bottom: 27px;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 0.5px;
    }

    &__list {
      gap: 30px;

      &-item {
        // .item-img {
        //   min-width: 401.71px;
        // }

        &.professionals {
          gap: 0;

          // .item-img__container {
          //   max-width: 43.489vw;
          // }

          .item-description {
            margin-top: -6px;
          }
        }

        &.confidence {
          .icon-container {
            .item-img__container {
              picture {
                img {
                  margin: 0 auto;
                }
              }
            }
          }
        }

        &.protection {
          gap: 15px;
        }

        .item-description {
          .description-container {
            .item-title {
              letter-spacing: 0.5px;
            }

            .description {
              letter-spacing: -0.1px;
            }
          }
        }
      }
    }

    &-picture {
      &__img {
        border-radius: 40px;
      }
    }
  }
}

@media (min-width: 768px) {
  .about-us {
    padding-bottom: 36.51px;

    &__title {
      margin-bottom: 33px;
    }

    &__list {
      &-item {
        flex-direction: row;
        gap: 20px;

        .item-description {
          width: 50%;

          .item-title {
            letter-spacing: 0.5px;
          }
        }

        // .item-img {
        //   max-width: 43.489vw;
        // }

        &.professionals {
          gap: 20px;
          align-items: center;

          .item-description {
            margin: 0;
          }

          // .item-img__container {
          //   .item-img {
          //     &.sm {
          //       display: none;
          //     }

          //     &.md {
          //       display: flex;
          //       min-width: 44vw;
          //     }
          //   }
          // }
        }

        &.protection {
          flex-direction: row-reverse;
          align-items: center;

          // .item-img__container {
          //   .item-img {
          //     width: 334px;
          //     min-width: auto;
          //   }
          // }
        }

        &.confidence {
          gap: 28px;
          margin-top: 0;

          .icon-container {
            .item-img__container {
              margin: 17px 0 0 10px;

              // .item-img {
              //   width: 316px;
              //   height: 343.49px;
              // }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .about-us {
    padding: 30px;
    border-radius: 50px;

    &__title {
      font-size: 45px;
      line-height: 118.19%;
      letter-spacing: 0px;
    }

    &__list {
      gap: 37.34px;

      &-item {
        justify-content: space-between;

        .item-description {
          max-width: 440px;

          .icon-container {
            width: 68px;
            height: 68px;

            .item-icon {
              width: 40.33px;
              height: 29.33px;
            }
          }

          .item-title {
            font-size: 32px;
            line-height: 38px;
            letter-spacing: 0.6px;
          }

          .description-container {
            display: flex;
            flex-direction: column;
            gap: 3px;

            .description {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }

        // .item-img {
        //   width: 64vw;
        //   margin-top: 0px;
        // }

        // &.professionals {
        //   .item-img__container {
        //     width: 100%;
        //     max-width: 44vw;

        //     .item-img {
        //       width: 41.338vw;

        //       &.md {
        //         min-width: fit-content;
        //       }
        //     }
        //   }
        // }

        // &.protection {
        //   .item-img__container {
        //     .item-img {
        //       width: 44vw;
        //     }
        //   }
        // }

        &.confidence {
          align-items: center;
          position: relative;
          flex-direction: row-reverse;

          .icon-container {
            position: absolute;
            top: 10px;
            left: 25px;

            .item-img__container {
              .item-img {
                height: auto;
                width: 395.46px;
                position: absolute;
                top: 0;
                left: 25px;
              }
            }
          }

          .item-description {
            .icon-container {
              position: initial;
            }
          }
        }
      }
    }

    &-picture {
      &__img {
        border-radius: 50px;
      }
    }
  }
}

@media (min-width: 1290px) {
  .about-us {
    padding: 50px;

    &__title {
      font-size: 60px;
      line-height: 118.19%;
    }

    &__list {
      gap: 0;

      &-item {
        .item-description {
          max-width: fit-content;

          .description-container {
            .description {
              font-size: 20px;
              line-height: 28px;
            }
          }
        }

        // .item-img {
        //   max-width: 29.5vw;
        //   margin-right: 25px;
        //   margin-top: -72px;
        // }

        &.professionals {
          gap: 46.45px;
          margin-left: 0;

          .item-img__container {
            margin-left: 25px;
            //   max-width: fit-content;

            //   .item-img {
            //     &.md {
            //       display: none;
            //     }

            //     &.lg {
            //       display: flex;
            //       width: 100%;
            //       max-width: 539.55px;
            //       margin: 0;
            //     }
            //   }
          }
        }

        &.confidence {
          margin-top: 45px;

          .icon-container {
            top: -65px;
            left: 40px;
          }

          .item-description {
            max-width: 579px;

            .description {
              letter-spacing: 1px;
            }

            .icon-container {
              position: initial;
            }
          }
        }

        &.protection {
          margin-top: -25px;
          align-items: center;
        }
      }
    }
  }
}
</style>
